<template>
  <div class="project-youtube">
    <div class="row" v-if="type === 'large'">
      <div class="col">
        <div class="youtube-container" v-bind:class="'ratio_'+this.aspectRatio">
          <iframe class="youtube-iframe"  v-bind:src="'https://www.youtube.com/embed/'+this.id+'?rel=0'" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
    </div>
    <div class="row justify-content-center" v-else>
      <div class="col-9">
        <div class="youtube-container" v-bind:class="'ratio_'+this.aspectRatio">
          <iframe class="youtube-iframe"  v-bind:src="'https://www.youtube.com/embed/'+this.id+'?rel=0'" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data () {
      return {
        variable: null
      }
    },
    name: 'ProjectYoutube',
      props: {
      type: String,
      id: String,
      aspectRatio: String,
      title: String
    },
    methods: {
      init () {
        // Init Function
      }
    },
    mounted () {
      this.init()
    }
  }
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import "src/assets/scss/custom.scss";
  @import "src/assets/scss/mixins.scss";
  .project-youtube{
    padding-top: 30px;
    padding-bottom: 30px;
    .youtube-container{
      position: relative;
      width: 100%;
      @include aspect-ratio(16, 9);
      .youtube-iframe{
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
      }
    }
    .ratio_16x9{
      @include aspect-ratio(16, 9);
    }
    .ratio_1_33x1{
      @include aspect-ratio(1.33, 1);
    }
    .ratio_3x2{
      @include aspect-ratio(3, 2);
    }
    .ratio_4x3{
      @include aspect-ratio(4, 3);
    }
    .ratio_2_35x1{
      @include aspect-ratio(2.35, 1);
    }
  }
</style>
